import { ThemeProvider } from 'styled-components';
import dynamic from 'next/dynamic';

import Layout from 'layouts';
import { Head } from 'humanity/components/head';
import { theme } from 'utils/theme.v2';
import { pageSettingsType } from 'types/pages/pageContainer.types';
import { storefrontPageType } from 'humanity/pages/storefrontPage/storefrontPage.types';
import BasicMasthead from 'humanity/sections/basicMasthead';
import { SimpleMasthead } from 'humanity/sections/simpleMasthead';
import withVwo from 'wrappers/withVwo';

const TestimonialsGrid = dynamic(() => import('humanity/sections/testimonialsGrid'));
const ProfileCardsSection = dynamic(
  () => import('humanity/sections/profileCardsSection')
);
const ImageTextBlockSection = dynamic(
  () => import('humanity/sections/imageTextBlockSection')
);

const FeaturedValueProp = dynamic(() => import('humanity/sections/featuredValueProp'));
const LargePricingCard = dynamic(() => import('humanity/sections/largePricingCard'));
const NumberedStepList = dynamic(() => import('humanity/sections/numberedStepList'));
const CompactBanner = dynamic(() => import('humanity/sections/compactBanner'));
const ValuePropList = dynamic(() => import('humanity/sections/valuePropList'));
const CtaBanner = dynamic(() => import('humanity/sections/ctaBanner'));
const FeaturedTestimonial = dynamic(
  () => import('humanity/sections/featuredTestimonial')
);
const PayrollIntegrations = dynamic(
  () => import('humanity/sections/payrollIntegrations')
);
const PricingCards = dynamic(() => import('humanity/sections/pricingCards'));
const FullWidthMedia = dynamic(() => import('humanity/sections/fullWidthMedia'));
const EmbeddedMedia = dynamic(() => import('humanity/sections/embeddedMedia'));
const Faqs = dynamic(() => import('humanity/sections/faqs'));
const SimpleRichTextSection = dynamic(
  () => import('humanity/sections/simpleRichTextSection')
);
const ValuePropTabs = dynamic(() => import('humanity/sections/valuePropTabs'));

const StorefrontPage = ({ pageSettings, pageContent }) => (
  <Layout
    type="primary"
    layout={pageSettings.layout}
    notes={pageContent.notesCollection?.items}
    disclosures={pageContent.disclosuresCollection?.items}
  >
    <Head
      title={pageSettings.seoMetadata?.title}
      description={pageSettings.seoMetadata?.description}
      url={`${process.env.NEXT_PUBLIC_HI_BASE_URL}${pageSettings.seoMetadata?.canonicalUrl}`}
      image={pageSettings.seoMetadata?.image?.url}
      noIndex={pageSettings.seoMetadata?.noIndex}
    />
    <ThemeProvider theme={theme}>
      {pageContent.sectionsCollection?.items?.map((section) => {
        switch (section?.__typename) {
          case 'SimpleMasthead':
            return (
              <SimpleMasthead
                key={section?.sys?.id}
                {...section}
                reviews={section?.reviewsCollection?.items}
                images={section?.imagesCollection?.items}
              />
            );
          case 'BasicMasthead':
            return (
              <BasicMasthead
                key={section?.sys?.id}
                variant={section?.variant}
                image={section?.image}
                imageAlignment={section?.imageAlignment}
                eyebrow={section?.eyebrow}
                heading={section?.heading}
                paragraph={section?.paragraph}
                form={section?.form}
                cta={section?.cta}
                contentPosition={section?.contentPosition}
                disclosures={section?.disclosures}
              />
            );
          case 'CompactBanner':
            return (
              <CompactBanner
                key={section?.sys?.id}
                heading={section?.heading}
                images={section?.imagesCollection?.items}
                bgColor={section?.bgColor}
                disclosures={section?.disclosures}
              />
            );
          case 'FeaturedValueProp':
            return (
              <FeaturedValueProp
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                image={section?.image}
                imageAlignment={section?.imageAlignment}
                imagePosition={section?.imagePosition}
                eyebrow={section?.eyebrow}
                heading={section?.heading}
                paragraph={section?.paragraph}
                disclosures={section?.disclosures}
                cta={section?.cta}
              />
            );
          case 'LargePricingCard':
            return (
              <LargePricingCard
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                image={section?.image}
                heading={section?.heading}
                subheading={section?.subheading}
                planIntroText={section?.planIntroText}
                baseFee={section?.baseFee}
                baseFeeText={section?.baseFeeText}
                perEmployeeFee={section?.perEmployeeFee}
                employeeFeeText={section?.employeeFeeText}
                cta={section?.cta}
                disclosures={section?.disclosures}
              />
            );
          case 'NumberedStepList':
            return (
              <NumberedStepList
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                steps={section?.stepsCollection?.items}
                cta={section?.cta}
              />
            );
          case 'ValuePropList':
            return (
              <ValuePropList
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                headingAlignment={section?.headingAlignment}
                subheading={section?.subheading}
                valueProps={section?.valuePropsCollection?.items}
                cta={section?.cta}
                disclosures={section?.disclosures}
              />
            );
          case 'CtaBanner':
            return (
              <CtaBanner
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                alignment={section?.alignment}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                subheading={section?.subheading}
                cta={section?.cta}
                valueProps={section?.valueProps}
              />
            );
          case 'FeaturedTestimonial':
            return (
              <FeaturedTestimonial
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                subheading={section?.subheading}
                quote={section?.quote}
                image={section?.image}
                author={section?.author}
                authorRole={section?.authorRole}
                cta={section?.cta}
                disclosures={section?.disclosures}
              />
            );
          case 'TestimonialsGrid':
            return (
              <TestimonialsGrid
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                subheading={section?.subheading}
                testimonials={section?.testimonialsCollection.items}
                disclosures={section?.disclosures}
              />
            );
          case 'PayrollIntegrations':
            return (
              <PayrollIntegrations
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                subheading={section?.payrollSubheading}
                integrationLogos={section?.integrationLogosCollection?.items}
                completeIntegrationsList={section?.completeIntegrationsList}
                cta={section?.cta}
                ignoreCtaUrl={section?.ignoreCtaUrl}
                modalCta={section?.modalCta}
              />
            );
          case 'PricingCards':
            return (
              <PricingCards
                key={section?.sys?.id}
                heading={section?.heading}
                banner={section?.banner}
                subheading={section?.subheading}
                pricingCards={section?.pricingCardsCollection?.items}
                savingsToggleText={section?.savingsToggleText}
                savingsToggleDefaultValue={section?.savingsToggleDefaultValue}
                disclosures={section?.disclosures}
                featuresHeading={section?.featuresHeading}
                features={section?.featuresCollection?.items}
                featuresDisclosures={section?.featuresDisclosures}
              />
            );
          case 'FullWidthMedia':
            return (
              <FullWidthMedia
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                subheading={section?.subheading}
                headingAlignment={section?.headingAlignment}
                media={section?.media}
                videoWidth={section?.videoWidth}
                videoHeight={section?.videoHeight}
                autoplay={section?.autoplay}
                showControls={section?.showControls}
                loop={section?.loop}
                disclosures={section?.disclosures}
              />
            );
          case 'EmbeddedMedia':
            return (
              <EmbeddedMedia
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                subheading={section?.subheading}
                headingAlignment={section?.headingAlignment}
                title={section?.title}
                url={section?.url}
                mediaWidth={section?.mediaWidth}
                mediaHeight={section?.mediaHeight}
                disclosures={section?.disclosures}
              />
            );
          case 'FaqSection':
            return (
              <Faqs
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                faqs={section?.faqsCollection?.items}
                additionalCopy={section?.additionalCopy}
              />
            );
          case 'SimpleRichTextSection':
            return (
              <SimpleRichTextSection
                key={section?.sys?.id}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                textAlignment={section?.textAlignment}
                content={section?.content}
                cta={section?.cta}
              />
            );
          case 'ValuePropTabs':
            return (
              <ValuePropTabs
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                eyebrow={section?.eyebrow}
                heading={section?.heading}
                tabs={section?.tabsCollection?.items}
              />
            );
          case 'SectionProfileCards':
            return (
              <ProfileCardsSection
                key={section.sys?.id}
                heading={section.heading}
                body={section.body}
                authorsCollection={section.authorsCollection}
              />
            );
          case 'SectionImageTextBlocks':
            return <ImageTextBlockSection key={section.sys?.id} {...section} />;
          default:
            return null;
        }
      })}
    </ThemeProvider>
  </Layout>
);

StorefrontPage.propTypes = {
  pageSettings: pageSettingsType.isRequired,
  pageContent: storefrontPageType.isRequired,
  // experimentData: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     campaignTitle: PropTypes.string,
  //     campaignId: PropTypes.string,
  //     meta: PropTypes.objectOf(PropTypes.string),
  //     campaignKey: PropTypes.string,
  //     variation: PropTypes.string,
  //     hinAnonymousId: PropTypes.string,
  //   })
  // ).isRequired,
};

export default withVwo(StorefrontPage);
